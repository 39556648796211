h1 {
	font-family: $font-franklingothic;
	font-weight: 300;
	font-size: 68px;
	line-height: 1;
	letter-spacing: -0.1px;
	color: #162b32;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

h2 {
	font-family: $font-franklingothic;
	font-weight: 300;
	font-size: 42px;
	line-height: 1.05;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

h3 {
	font-family: $font-franklingothic;
	font-weight: 300;
	font-size: 34px;
	line-height: 1.05;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

p {
	margin: 32px 0;
	font-size: 16px;
	line-height: 1.75;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}
