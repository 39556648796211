.swiper-wrapper {
	transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.slider-btn {
	position: relative;
	display: flex;
	flex: none;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	outline: none;
	border: none;
	border-radius: 2px;
	width: 72px;
	height: 72px;
	box-shadow: none;
	background-color: #f7f7f7;
	backface-visibility: hidden;
	transform: translateZ(0);
	transition: background-color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

	svg {
		position: relative;
		z-index: 2;
		display: block;
		width: 24px;
		height: 24px;
		fill: #20222d;
		stroke: none;
		stroke-width: 2px;
		transform: translateZ(0);
	}

	.button-shim {
		background-color: #eceded;
	}

	&.is-disabled {
		display: none;
	}

	&--prev {
		margin-right: 4px;
	}

	&--white {
		background-color: $color-white;
	}

	.is-bg-gray & {
		background-color: $color-white;
	}
}

.slider-pagination {
	display: block;
	overflow: hidden;
	margin-left: 34px;
	max-width: 150px;

	&__inner {
		display: flex;
		align-items: center;
		transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

		&.is-disabled {
			display: none;
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex: none;
		align-items: center;
		justify-content: center;
		margin: 7.5px;
		outline: none;
		border-radius: 50%;
		width: 22px;
		height: 22px;
		box-sizing: border-box;
		background-color: transparent;
		opacity: 1;
		cursor: pointer;

		&::before {
			content: "";
			position: relative;
			z-index: 2;
			display: block;
			flex: none;
			border-radius: 50%;
			width: 6px;
			height: 6px;
			background-color: rgba(#202020, 0.2);
			transition:
				background-color 0.15s cubic-bezier(0.25, 0.1, 0.25, 1),
				opacity 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			border-radius: 50%;
			width: 100%;
			height: 100%;
			background-color: rgba(#162b32, 0.1);
			transform: scale(0);
			transition: transform 0.5s ease;
		}

		.is-device-desktop &:hover {
			&::before {
				background-color: #202020;
			}
		}

		&.is-active,
		&.swiper-pagination-bullet-active {
			cursor: auto;

			&::before {
				background-color: #202020;
			}

			&::after {
				transform: scale(1);
				animation: pagTimer 7s linear both;

				.park-variants & {
					animation: pagTimer 11s linear both;

					.is-autoplay-stopped & {
						transform: scale(1);
						animation: none;
					}
				}

				.park-variants .variant-slider__image & {
					animation: pagTimer 3s linear both;
				}

				.park-variants .variant-slider__image.is-autoplay-stopped & {
					transform: scale(1);
					animation: none;
				}

				.is-autoplay-stopped & {
					transform: scale(1);
					animation: none;
				}
			}
		}
	}

	.is-controls-white &,
	&--white {
		.slider-pagination__item {
			&::before {
				background-color: $color-white;
			}

			&::after {
				background-color: rgba($color-white, 0.2);
			}

			.is-device-desktop &:hover {
				&::before {
					background-color: $color-white;
					opacity: 0.5;
				}
			}
		}
	}

	&.is-disabled {
		display: none;
	}

	.variant-slider--houses &,
	.big-slider--reverse & {
		margin-right: 34px;
		margin-left: 0;
	}
}

@keyframes pagTimer {
	10% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.4);
	}
}

.image-slider {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	transform: translateZ(0);

	&__item {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		width: 100%;
		height: 100%;
		transform: translateZ(0);

		&::after {
			content: "";
			display: table;
			clear: both;
		}

		img,
		> .img {
			display: block;
			flex: none;
			overflow: hidden;
			width: 100%;
			height: auto;
			margin: 0 auto;
			transform: translateZ(0);
		}

		> .img {
			height: 100%;
		}
	}
}

.big-slider {
	position: relative;
	margin: auto;
	width: 100%;
	max-width: 1104px;

	&__nav {
		position: absolute;
		left: 0;
		top: 100%;
		display: flex;
		align-items: center;

		.big-slider--reverse & {
			left: auto;
			right: 0;
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		width: 100%;
		box-sizing: border-box;
	}

	&__content {
		padding-top: 72px;
		max-width: 310px;

		.big-slider--reverse & {
			max-width: 310px;
		}
	}

	&__image {
		position: relative;
		flex: none;
		width: 736px;
		height: 440px;

		&__bg {
			position: absolute;
			right: -72px;
			bottom: -72px;
			z-index: 0;
			width: 368px;
			height: 324px;
			background-color: #f7f7f7;

			@media (max-width: 1260px) {
				right: -45px;
			}
		}
	}
}

.block-slider {
	position: relative;
	margin-left: 48px;
	width: 550px;

	.swiper-container {
		overflow: visible;
	}

	&__nav {
		position: absolute;
		left: 0;
		top: calc(100% + 32px);
		display: flex;
		align-items: center;
	}

	&__item {
		position: relative;
		overflow: hidden;
		border-radius: 2px;
		width: 550px;
		height: 340px;
		box-sizing: border-box;
		background-color: #f7f7f7;
		transform: translateZ(0);
		transition: margin 0.25s cubic-bezier(0.42, 0, 0.58, 1);
		will-change: margin;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background-color: #f7f7f7;
			transform: translateZ(0);
			transition:
				opacity 0.8s cubic-bezier(0.25, 0.1, 0.25, 1),
				background-color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
			pointer-events: none;

			.is-bg-gray & {
				background-color: $color-white;
			}
		}

		&.swiper-slide-active {
			&::before {
				opacity: 0;
				transform: translateZ(0);
			}

			.block-slider__dot {
				background-color: $color-white;
			}

			.block-slider__content {
				color: $color-white;
			}
		}

		.is-swiping &,
		[style="cursor: grabbing;"] & {
			margin-right: 40px !important; // stylelint-disable-line
		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		overflow: hidden;
		width: 100%;
		height: 100%;
		transform: translateZ(0);

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(30, 33, 40, 0.6);
			transform: translateZ(0);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translateZ(0);
		}
	}

	&__video {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		overflow: hidden;
		width: 100%;
		height: 100%;
		transform: translateZ(0);

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(30, 33, 40, 0.6);
			transform: translateZ(0);
		}

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translateZ(0);
		}
	}

	&__content {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 3;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 56px;
		width: 100%;
		height: 100%;
		color: #162b32;
		transform: translateZ(0);
	}

	&__dot {
		flex: none;
		margin-bottom: 52px;
		border-radius: 2px;
		width: 8px;
		height: 8px;
		background-color: #d52340;
	}

	&__title {
		margin-top: auto;
		margin-bottom: 24px;
		max-width: 280px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 42px;
		line-height: 1.05;
	}

	&__desc {
		max-width: 380px;
		font-size: 16px;
		line-height: 1.75;
	}

	&__heading {
		margin-top: -5px;
		max-width: 300px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 34px;
		line-height: 1.06;
	}

	&__num {
		position: absolute;
		right: 90px;
		bottom: 48px;
		z-index: 1;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		width: auto;

		.num {
			font-family: $font-bebasneue;
			font-size: 180px;
			line-height: 0.7;
			letter-spacing: -8px;
		}

		.tip {
			position: absolute;
			left: 100%;
			bottom: 0;
			margin-left: calc(1em + 15px);
			font-family: $font-franklingothic;
			font-size: 20px;
			line-height: 0.7;
			letter-spacing: 1.6px;
			text-transform: uppercase;
			transform: rotate(-90deg);
			transform-origin: 0 100%;

			.is-os-mac &,
			.is-os-ios & {
				transform: rotate(-90deg) translateX(7px);
				transform-origin: 0 0;
			}
		}

		.swiper-slide-active & {
			color: $color-white !important; // stylelint-disable-line
		}
	}
}

.variant-slider {
	position: relative;
	margin-top: 170px;
	margin-left: 48px;
	max-width: 920px;

	.swiper-container {
		overflow: visible;
		width: 100%;
	}

	&__nav {
		position: absolute;
		left: 277px;
		bottom: calc(100% + 32px);
		display: flex;
		align-items: center;

		.variant-slider--houses & {
			left: auto;
			right: -173px;
			top: calc(100% + 32px);
			bottom: auto;
		}
	}

	&__item {
		display: flex;
		overflow: hidden;
		border-radius: 2px;
		width: 100%;
		height: auto;
		box-sizing: border-box;
		background-color: #f7f7f7;
		transition: margin 0.25s cubic-bezier(0.42, 0, 0.58, 1);
		will-change: margin;

		.is-device-desktop &:hover {
			.variant-slider__image {
				&::before {
					opacity: 0;
				}

				> img {
					transform: scale(1.1);
				}
			}
		}

		.is-swiping & {
			margin-right: 40px !important; // stylelint-disable-line
		}

		.park-variants & {
			height: 560px;
		}

		.index-offers &,
		.about-news & {
			cursor: pointer;
		}
	}

	&__image {
		position: relative;
		overflow: hidden;
		width: 50%;
		height: 100%;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(14, 32, 38, 0.4);
			transition: opacity 0.4s ease;
			pointer-events: none;
		}

		> img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		.slider-pagination {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 20px;
			z-index: 3;
			justify-content: center;
			margin: 0 auto;
		}

		.swiper-container {
			width: 100%;
			height: 100%;
		}

		.swiper-slide {
			box-sizing: border-box;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
			}
		}

		.swiper-slide-active & {
			&::before {
				opacity: 0;
			}
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 56px;
		width: 50%;
		height: 100%;
		transition: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
	}

	&__content {
		width: 100%;
		margin-top: 15px;

		h3 {
			margin-bottom: 16px;
		}

		p {
			margin: 16px 0;
			max-width: 310px;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__date {
		margin-bottom: auto;
		font-family: $font-franklingothic;
		font-size: 20px;
		line-height: 0.7;
		letter-spacing: 1.6px;
		text-transform: uppercase;
		color: #162b32;
	}

	&__desc {
		margin-top: -10px;
		margin-bottom: 32px;
		font-size: 15px;
		line-height: 1.6;
		color: rgba(#162b32, 0.5);
	}

	&__modalcont {
		display: none;
	}

	&--houses {
		margin-top: 0;
	}
}

.rewards-slider {
	position: relative;

	.swiper-container {
		overflow: visible;
		width: 450px;
	}

	&__nav {
		position: absolute;
		left: 0;
		bottom: -36px;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	&__item {
		padding-top: 65px;
		width: 450px;
		height: 355px;
		opacity: 0.3;
		transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

		&.swiper-slide-active,
		&.swiper-slide-prev,
		&.swiper-slide-next {
			opacity: 1;
		}
	}

	&__date {
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 22px;
		line-height: 1.64;
		text-align: center;
		color: #43646e;
	}

	&__text {
		margin: 61px auto 18px;
		max-width: 200px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 34px;
		line-height: 1.06;
		text-align: center;
		color: $color-white;
	}

	&__desc {
		margin: 0 auto;
		max-width: 195px;
		font-size: 15px;
		line-height: 1.6;
		text-align: center;
		color: rgba($color-white, 0.4);
	}
}

.photo-slider {
	position: relative;
	margin-top: 166px;
	margin-left: 48px;
	width: 440px;

	.swiper-container {
		overflow: visible;
	}

	&__nav {
		position: absolute;
		left: 277px;
		bottom: calc(100% + 32px);
		display: flex;
		align-items: center;
	}

	&__item {
		width: 440px;
		height: 440px;
		//background: url("../images/inst1.jpg") center no-repeat;
		transition: margin 0.25s cubic-bezier(0.42, 0, 0.58, 1);
		will-change: margin;

		.is-swiping &,
		[style="cursor: grabbing;"] & {
			margin-right: 40px !important; // stylelint-disable-line
		}
	}
}

.files-slider {
	position: relative;

	&__nav {
		position: absolute;
		left: 0;
		top: calc(100% + 56px);
		display: flex;
		align-items: center;
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 25px 35px 23px 36px;
		width: 532px;
		height: 120px;
		box-sizing: border-box;
		text-decoration: none;
		color: #162b32;
		background-color: $color-white;
		transition:
			background-color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1),
			color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

		.is-device-desktop &:hover {
			color: $color-white;
			background-color: #25b7cc;
		}
	}

	&__extension {
		margin-right: 36px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 40px;
		line-height: 1;
		text-transform: uppercase;
	}

	&__desc {
		font-size: 15px;
		line-height: 1.6;
	}

	&__tip {
		position: absolute;
		right: 0;
		top: calc(100% + 56px);
		max-width: 422px;
		font-size: 15px;
		line-height: 1.6;
		color: rgba(#162b32, 0.6);

		.hover-cont {
			position: relative;
			z-index: 3;
			display: inline-block;
			margin-right: 4px;
			color: #162b32;
			cursor: pointer;

			&__inner {
				position: absolute;
				left: 50%;
				bottom: calc(100% + 10px);
				border-radius: 3px;
				padding: 24px 32px;
				width: 403px;
				box-shadow: 0 2px 2px 0 rgba(14, 32, 38, 0.2);
				background-color: $color-white;
				opacity: 0;
				transform-origin: 0 100%;
				transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
				pointer-events: none;
			}

			.is-device-desktop &:hover {
				.hover-cont__inner {
					opacity: 1;
				}
			}
		}
	}
}

.bigphoto-slider {
	position: relative;

	.image-slider {
		width: 100%;
		height: 621px;
	}

	&__nav {
		position: absolute;
		right: 32px;
		bottom: 32px;
		z-index: 5;
		display: flex;
		align-items: center;
	}

	&__item {
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.banks-slider {
	position: relative;

	.swiper-container {
		overflow: visible;
		margin-left: 168px;
		width: 460px;
	}

	.slider-pagination {
		margin-right: 42px;
		margin-left: 0;
	}

	&__nav {
		position: absolute;
		right: 48px;
		top: calc(100% + 32px);
		display: flex;
		align-items: center;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		border-radius: 8px;
		padding: 48px 48px 56px;
		width: 460px;
		height: 340px;
		box-sizing: border-box;
		text-decoration: none;
		color: $color-white;
		background-image: linear-gradient(to left, #7bc765, #499e53);
		background-position: 0 0;
		background-size: 640px 100%;
		transition:
			margin 0.25s cubic-bezier(0.42, 0, 0.58, 1),
			background-position 0.6s cubic-bezier(0.42, 0, 0.58, 1);
		will-change: margin;

		.is-device-desktop &:hover {
			background-position: 100% 0;

			.banks-slider__logo__tip {
				opacity: 1;
				pointer-events: all;
			}
		}

		&.-tkb {
			background-image: linear-gradient(to left, #ffc46b, #f59d15);

			.banks-slider__logo svg {
				width: 48px;
				height: 48px;
			}
		}

		&.-rshb {
			background-image: linear-gradient(to left, #beeb5e, #8aad3d);

			.banks-slider__logo svg {
				width: 48px;
				height: 55px;
			}
		}

		&.-sber {
			background-image: linear-gradient(to left, #7bc765, #499e53);

			.banks-slider__logo svg {
				width: 48px;
				height: 46px;
			}
		}

		&.-sb {
			background-image: linear-gradient(to right, #0f9463, #33cc94);

			.banks-slider__logo svg {
				width: 48px;
				height: 45px;
			}
		}

		&.-bz {
			background-image: linear-gradient(to left, #75e6e2, #35b0ac);

			.banks-slider__logo svg {
				width: 48px;
				height: 76px;
			}
		}

		&.-mib {
			background-image: linear-gradient(to left, #6989e0, #4a67b5);

			.banks-slider__logo svg {
				width: 48px;
				height: 48px;
			}
		}

		&.-uralsib {
			background-image: linear-gradient(to left, #5b6eba, #394a8f);

			.banks-slider__logo svg {
				width: 48px;
				height: 72px;
			}
		}

		&.-eb {
			background-image: linear-gradient(to left, #e673aa, #b83574);

			.banks-slider__logo svg {
				width: 48px;
				height: 48px;
			}
		}

		&.-dk {
			background-image: linear-gradient(to left, #f26d6d, #cc334a);

			.banks-slider__logo svg {
				width: 48px;
				height: 48px;
			}
		}

		&.-msp {
			background-image: linear-gradient(to left, #f26d6d, #cc334a);

			.banks-slider__logo svg {
				width: 48px;
				height: 30px;
			}
		}

		.is-swiping & {
			margin-right: 40px !important; // stylelint-disable-line
		}
	}

	&__logo {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 48px;

		&__tip {
			font-size: 15px;
			line-height: 1.6;
			color: rgba($color-white, 0.6);
			opacity: 0;
			transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
			cursor: pointer;
			pointer-events: none;

			.is-device-desktop &:hover {
				color: $color-white;
			}
		}

		svg {
			display: block;
			flex: none;
			margin-right: 24px;
			width: 48px;
			height: 48px;
		}
	}

	&__value {
		margin-top: auto;
		margin-bottom: 8px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 26px;
		line-height: 1.08;
		transition: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition-delay: 0.3s;

		b {
			font-weight: 300;
			font-size: 64px;
		}

		.is-tip-hovered & {
			opacity: 0;
			transition-delay: 0s;
			pointer-events: none;
		}
	}

	&__desc {
		max-width: 133px;
		font-size: 15px;
		line-height: 1.6;
		transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition-delay: 0.3s;

		.is-tip-hovered & {
			opacity: 0;
			transition-delay: 0s;
			pointer-events: none;
		}
	}

	&__license {
		position: absolute;
		left: 56px;
		right: 56px;
		bottom: 56px;
		font-size: 15px;
		line-height: 1.6;
		color: rgba($color-white, 0.7);
		opacity: 0;
		transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
		pointer-events: none;

		.is-tip-hovered & {
			opacity: 1;
			transition-delay: 0.3s;
			pointer-events: all;
		}
	}
}
