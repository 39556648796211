.hero {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	min-height: 680px;
	background-color: rgba(30, 33, 40, 1);

	.slider-pagination {
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
		margin-left: 0;
	}

	&__shim {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 5;
		width: 100%;
		height: 100%;
		background-color: rgba(30, 33, 40, 1);
		transform-origin: 0 0;
	}

	&__slider {
		width: 100%;
		height: 100%;

		.hero-item {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__items {
		width: 100%;
		height: 100%;
	}

	&__scrolldown {
		position: absolute;
		left: 53px;
		bottom: 48px;
		z-index: 3;
		border: none;
		outline: none;
		box-shadow: none;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 18px;
		line-height: 0.78;
		letter-spacing: 1.6px;
		text-align: center;
		text-transform: uppercase;
		color: $color-white;
		background-color: transparent;
		transform: translateY(140px);

		span {
			opacity: 0.7;
			animation: blinkBtn 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
			animation-delay: 0.3s;
		}

		svg {
			display: block;
			margin-top: 24px;
			width: 40px;
			height: 20px;
			fill: $color-white;
			opacity: 0.7;
			animation: blinkBtn 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
		}
	}

	&__controls {
		position: absolute;
		right: 48px;
		top: 0;
		bottom: 0;
		z-index: 3;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 234px;
		transform: translateX(140px);

		.slider-btn {
			margin-bottom: 4px;
		}
	}

	&__title {
		margin-top: auto;
		max-width: 870px;
		font-family: $font-franklingothic;
		font-size: 68px;
		line-height: 1;
		letter-spacing: -0.1px;

		.page-about &,
		.page-contacts &,
		.page-park & {
			font-size: 100px;
			letter-spacing: -1.5px;
		}

		.page-contacts & {
			margin-top: 0;
		}
	}

	&__desc {
		margin-top: 24px;
		font-size: 16px;
		line-height: 1.75;

		.hero-about & {
			max-width: 506px;
		}

		.hero-contacts & {
			max-width: 400px;
		}

		.hero-park & {
			max-width: 400px;
		}
	}

	&__btn {
		margin-top: 48px;
		max-width: 283px;

		&.button {
			margin: 48px 0 0;
			max-width: 283px;

			.page-about & {
				.button-shim {
					background-color: #eceded;
				}
			}
		}

		&--white {
			&.button {
				display: inline-flex;
				padding: 0 43px;
				width: auto;
				background-color: $color-white;

				> span {
					position: relative;
				}

				> .helper-cont {
					position: absolute;
				}

				> .button-shim {
					position: absolute;
					background-color: #eceded;
				}
			}
		}
	}

	&__stats {
		display: flex;
		margin-top: auto;
		height: 68px;
	}

	&__statitem {
		flex: none;
		margin-right: 75px;

		&--addmargin {
			margin-left: 30px;
		}

		.hero__stats--avenue & {
			margin-right: 35px;
		}
	}

	&__tip {
		display: block;
		flex: none;
		position: relative;
		z-index: 2;
		margin-top: -25px;
		margin-right: 20px;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		width: 50px;
		height: 160px;
		background-color: #c3122f;

		span {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			display: block;
			font-family: $font-franklingothic;
			font-size: 20px;
			line-height: 0.7;
			letter-spacing: 1.6px;
			color: #ffffff;
			text-transform: uppercase;
			transform-origin: 100% 0;
			transform: translate3d(-31px, 23px, 0) rotate(-90deg);
		}

		&--second {
			margin-left: 30px;
		}
	}

	&__num {
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 26px;
		line-height: 1;

		b {
			font-weight: 300;
			font-size: 42px;
		}

		.page-about & {
			font-size: 22px;

			b {
				font-size: 40px;
			}
		}
	}

	&__numtip {
		margin-top: 6px;
		font-size: 15px;
	}

	&.hero-contacts {
		max-height: 614px;
	}
}

.hero-item {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	color: $color-white;
	transform: translate3d(0, 0, 0);

	&__image {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		overflow: hidden;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		transform: translate3d(0, 0, 0);

		&__shim {
			position: absolute;
			left: -10%;
			right: 0;
			top: -10%;
			bottom: 0;
			z-index: 2;
			width: 120%;
			height: 120%;
			background-color: rgba(30, 33, 40, 0.75);
			transform: translate3d(0, 0, 0);
		}

		&__inner {
			overflow: hidden;
			width: 100%;
			height: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			transform: translate3d(0, 0, 0);

			video {
				display: block;
				width: 100%;
			}
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__inner {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin: auto;
		padding-top: 72px;
		padding-bottom: 48px;
		height: 100%;
		max-width: 920px;
		transform: translate3d(0, 0%, 0);

		.is-browser-ie & {
			justify-content: flex-start;
		}
	}

	&:first-child:not(.swiper-slide-duplicate) {
		.hero-item__image {
			transform: translate3d(0, 100%, 0);

			&__shim {
				opacity: 0;
			}
		}

		.hero__title {
			opacity: 0;
			transform: translateY(20px);
		}

		.hero__desc {
			opacity: 0;
			transform: translateY(20px);
		}

		.hero__btn {
			opacity: 0;
			transform: translateY(20px);
		}

		.hero__stats {
			transform: translateY(140px);
		}
	}
}

@keyframes blinkBtn {
	0% {
		opacity: 0.7;
	}

	25% {
		opacity: 1;
	}

	50% {
		opacity: 0.7;
	}
}
