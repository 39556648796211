// stylelint-disable no-empty-source

@font-face {
	src: url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.woff") format("woff");
	font-family: "Open Sans";
	font-weight: 300;
	font-style: normal;
}

@font-face {
	src: url("../fonts/FranklinGothicBookCmpITC-Reg.woff2") format("woff2"), url("../fonts/FranklinGothicBookCmpITC-Reg.woff") format("woff");
	font-family: "Franklin Gothic";
	font-weight: 300;
	font-style: normal;
}

@font-face {
	src: url("../fonts/BebasNeueRegular.woff") format("woff");
	font-family: "BebasNeue";
	font-weight: 300;
	font-style: normal;
}

@font-face {
	src: url("../fonts/MyriadPro-BoldCond.woff2") format("woff2"), url("../fonts/MyriadPro-BoldCond.woff") format("woff");
	font-family: "MyriadPro";
	font-weight: 700;
	font-style: normal;
}
