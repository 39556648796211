.heading {
	position: relative;
	display: flex;
	margin-bottom: 88px;

	&__number {
		position: absolute;
		left: -44px;
		top: -72px;
		display: flex;
		flex: none;
		justify-content: center;
		overflow: hidden;
		border-radius: 2px;
		padding-top: 64px;
		width: 184px;
		height: 620px;
		background-color: #f7f7f7;
		transform: translateY(0);
		transition: background-color 0.6s cubic-bezier(0.4, 0, 0.6, 1);

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: #d52340;
			transform: scaleY(0);
			transform-origin: 0 0;
			transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		svg {
			position: relative;
			z-index: 2;
			display: block;
			height: 266px;
			fill: $color-white;
			transition: fill 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		&.is-filled {
			&::before {
				transform: scaleY(1);
			}
		}
	}

	&__content {
		width: 100%;
		flex: 1;
		margin-left: 48px;

		.heading__number ~ & {
			margin-left: 325px;
		}
	}

	&__title {
		margin-bottom: 24px;
		font-family: $font-franklingothic;
		font-size: 20px;
		line-height: 1;
		letter-spacing: 1.6px;
		text-transform: uppercase;
		color: #d52340;
	}

	&__text {
		max-width: 830px;
		font-family: $font-franklingothic;
		font-size: 68px;
		line-height: 1;
		letter-spacing: -0.1px;
		color: #162b32;

		.park-houses & {
			max-width: 100%;
		}
	}

	&__desc {
		margin-top: 34px;
		max-width: 640px;
		font-size: 16px;
		line-height: 1.75;
		color: rgba(#162b32, 0.7);

		a {
			text-decoration: none;
			color: rgba(#162b32, 0.7);
			transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

			.is-device-desktop &:hover {
				color: #c3122f;
			}
		}
	}

	&__button {
		&.button {
			margin-top: 55px;
			margin-left: 0;
			max-width: 253px;
		}
	}

	&--gray {
		.heading__number {
			background-color: $color-white;

			svg {
				fill: #f7f7f7;
			}
		}
	}

	&--white {
		.heading__title {
			color: $color-white;
		}

		.heading__text {
			color: $color-white;
		}

		.heading__desc {
			color: rgba($color-white, 0.7);
		}
	}

	.is-bg-gray & {
		&__number {
			background-color: $color-white;

			svg {
				fill: #f7f7f7;
			}
		}
	}
}
