.park-info {
	margin: 225px auto 216px;
	max-width: 1200px;

	.heading {
		&__desc {
			max-width: 600px;
		}
	}

	.videoblock {
		&__video {
			height: 414px;
		}
	}
}

.park-props {
	margin: 216px auto 205px;
	max-width: 1200px;

	.heading {
		margin-bottom: 116px;

		&__number {
			height: 970px;
		}
	}

	.button {
		display: none;
		margin-top: 16px;
	}
}

.park-stats {
	margin: 205px auto 184px;
	padding: 118px 0 61px;

	.heading {
		margin-bottom: 100px;

		&__text {
			max-width: 880px;
		}

		&__desc {
			max-width: 611px;
		}
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}

.park-variants {
	overflow: hidden;
	margin: 95px auto 102px;
	padding-top: 80px;
	padding-bottom: 110px;

	.heading {
		&__text {
			max-width: 675px;
		}

		&__desc {
			max-width: 550px;
		}
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}

	&--flats {
		margin-top: 132px;
		margin-bottom: 104px;
	}
}

.house-props {
	margin-top: auto;
	width: 100%;

	.modal-props {
		display: none;
	}

	&__row {
		display: flex;
		justify-content: flex-start;
		width: 100%;
	}

	&__col {
		margin-right: 40px;
	}

	&__name {
		font-size: 15px;
		line-height: 1.6;
		color: rgba(#162b32, 0.5);
	}

	&__val {
		margin-bottom: 56px;
		font-family: $font-franklingothic;
		font-size: 26px;
		line-height: 1.15;
		color: #162b32;
	}

	&__btn {
		color: $color-white;
		background-color: #364246;

		.button-shim {
			background-color: #3d4e54;
		}
	}
}

.park-call {
	padding-top: 176px;
	padding-bottom: 190px;

	.heading {
		margin-bottom: 88px;
	}
}

.park-houses {
	margin: 188px auto 270px;
	max-width: 1200px;

	@media (max-width: 1270px) {
		overflow: hidden;
		margin: 116px auto 198px;
		padding: 72px 0;
	}
}

.park-structure {
	overflow: hidden;
	margin: 198px auto 0;
	padding-top: 72px;
	padding-bottom: 224px;

	.heading {
		&__desc {
			max-width: 550px;
		}
	}

	.block-slider__item {
		background-color: #f7f7f7;
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}

.park-map {
	height: 680px;
	background-color: #f7f7f7;

	&__addr {
		display: none;
	}

	&__container {
		position: relative;
		width: 100%;
		height: 100%;

		&__inner {
			width: 100%;
			height: 100%;
		}
	}
}

.park-security {
	overflow: hidden;
	margin: 112px auto 114px;
	padding-top: 80px;
	padding-bottom: 110px;

	.block-slider__item {
		background-color: #f7f7f7;
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}

.park-graysection {
	overflow: hidden;
	padding-top: 120px;
	padding-bottom: 128px;

	.heading {
		&__content {
			margin-left: 0;
		}
	}
}

.park-files {
	margin: 0 auto 282px;
	max-width: 1104px;

	.heading {
		margin-bottom: 56px;

		&__text {
			max-width: 880px;
		}
	}
}

.park-progress {
	margin: 282px auto 0;
	max-width: 1104px;

	.heading {
		margin-top: 0;

		&__text {
			max-width: 610px;
		}
	}

	.slider-pagination {
		margin-right: 42px;
		margin-left: 0;
	}
}

.park-features {
	margin: 176px auto 240px;
	max-width: 1200px;

	.heading {
		&__desc {
			max-width: 480px;
		}
	}
}

.park-banks {
	overflow: hidden;
	margin: 240px auto 147px;
	padding-bottom: 104px;

	.heading {
		&__desc {
			max-width: 415px;
		}
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}
