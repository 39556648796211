.gift {
	position: relative;
	overflow: hidden;
	border-top: 1px solid rgba(247, 247, 247, 0.1);
	background-color: #0e2026;
	height: 100vh;
	min-height: 600px;
	max-height: 1080px;

	&__inner {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin: auto;
		height: 100%;
		max-width: 1104px;
	}

	&__content {
		position: relative;
		z-index: 3;
		margin: auto 0;
		max-width: 350px;
		color: #fff;
	}

	&__tip {
		font-family: $font-franklingothic;
		margin-bottom: 25px;
		font-size: 20px;
		line-height: 0.7;
		letter-spacing: 1.8px;
		text-transform: uppercase;
		color: #fff;
	}

	&__title {
		font-family: $font-franklingothic;
		font-size: 68px;
		line-height: 1;
		letter-spacing: -0.1px;
		color: #fff;
	}

	&__subtitle {
		margin-top: 35px;
		font-family: $font-opensans;
		font-size: 16px;
		line-height: 1.75;
		color: rgba(#fff, 0.8);
	}

	&__video {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;

		.preview,
		.video {
			position: absolute;
			left: 0;
			top: 0;
			overflow: hidden;
			width: 100%;
			height: 100%;

			video,
			img {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		img {
			z-index: 1;
		}

		.preview {
			z-index: 2;
			transition: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		.video {
			z-index: 1;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 80px;

		.buttongroup {
			position: relative;
			width: 72px;
			height: 72px;
		}

		.video-play-btn {
			position: absolute;
			left: 0;
			top: 0;
			background-color: #fff;
			transition: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

			svg {
				fill: #20222d;
			}

			.button-shim {
				background-color: #eceded;
			}
		}

		.js-pause {
			opacity: 0;
			pointer-events: none;
		}

		.desc {
			margin-left: 30px;
		}

		.time {
			font-family: $font-franklingothic;
			font-size: 26px;
			line-height: 1.15;
			color: #fff;
		}

		.tip {
			font-family: $font-opensans;
			font-size: 15px;
			line-height: 1.6;
			color: rgba(#fff, 0.7);

			&.tip-elapsed {
				display: none;
			}
		}
	}

	&.is-playing {
		.gift__button {
			.js-play {
				opacity: 0;
				pointer-events: none;
			}

			.js-pause {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&.is-inprogress {
		.gift__button {
			.tip {
				display: none;

				&.tip-elapsed {
					display: block;
				}
			}
		}

		.gift__video {
			.preview {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}
