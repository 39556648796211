*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
}

body {
	position: relative;
	font-family: $font-opensans;
	color: #162b32;
	transition: background-color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

	&.is-modal-opened,
	&.is-menu-opened {
		overflow: hidden;
	}

	&.is-bg-gray {
		background-color: #f7f7f7;
	}
}

input,
textarea,
button {
	font-family: $font-opensans;
}

.page {
	overflow: hidden;
}

.l-wrapper {
	margin-right: auto;
	margin-left: auto;
	max-width: 1280px;
}

.is-hidden {
	display: none !important; //stylelint-disable-line
}

.is-opac-hidden {
	opacity: 0 !important; //stylelint-disable-line
}

.helper-video,
.helper-canvas {
	position: absolute;
	left: -9000px;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
}

.ruble-sign {
	.is-os-mac & {
		font-family: "Helvetica Neue";
		font-weight: 400;
	}
}
