.footer {
	background-color: #0e2026;

	&__inner {
		display: flex;
		align-items: center;
		margin: 0;
		padding-left: 90px;
		max-width: 1440px;
	}

	&__row {
		&--1 {
			padding: 46px 0;
		}

		&--2 {
			border-top: 1px solid  rgba(#f7f7f7, 0.1);
			padding: 24px 0 30px;
			font-size: 15px;
			line-height: 1.6;
			color: rgba($color-white, 0.7);
		}
	}

	&__title {
		margin-bottom: 14px;
		font-size: 15px;
		line-height: 1.6;
		color: rgba($color-white, 0.4);
	}

	&__links {
		a {
			font-family: $font-franklingothic;
			font-size: 23px;
			line-height: 1;
			letter-spacing: -0.1px;
			text-decoration: none;
			color: $color-white;
			transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

			&:not(:last-child) {
				margin-right: 32px;
			}

			.is-device-desktop &:hover {
				color: #c3122f;
			}
		}

		&:nth-child(1) {
			margin-right: 136px;
		}

		&:nth-child(2) {
			margin-right: 114px;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 72px;

		a {
			margin-left: 4px;
			text-decoration: none;
			color: $color-white;
			transition: color 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);

			.is-device-desktop &:hover {
				color: #c3122f;
			}
		}
	}

	&__copyright {
		margin-right: 72px;
	}

	&__policy {
		border: none;
		border-bottom: 1px dotted rgba($color-white, 0.3);
		padding: 0;
		box-shadow: none;
		white-space: nowrap;
		text-decoration: none;
		color: rgba($color-white, 0.7);
		background-color: transparent;
		transition: all 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);

		.is-device-desktop &:hover {
			border-color: rgba($color-white, 0.6);
			color: $color-white;
		}
	}
}

.socials {
	display: flex;
	align-items: center;

	a {
		position: relative;
		z-index: 2;
		display: block;
		flex: none;
		width: 20px;
		height: 20px;
		transform: translateZ(0);

		&:not(:last-child) {
			margin-right: 24px;
		}

		.is-device-desktop &:hover {
			svg {
				opacity: 0;
			}

			.hover-icon {
				opacity: 1;
			}
		}
	}

	svg,
	img {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		display: block;
		max-width: 20px;
		max-height: 20px;
		fill: $color-white;
		transform: translateZ(0);
		transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
	}

	.hover-icon {
		opacity: 0;
	}
}

.callback-button {
	position: fixed;
	right: 48px;
	bottom: 48px;
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	border-radius: 50%;
	width: 72px;
	height: 72px;
	box-sizing: border-box;
	background-color: #d52340;
	cursor: pointer;

	&::before {
		content: "";
		position: absolute;
		z-index: 2;
		display: block;
		border: solid 1px #0e2026;
		border-radius: 50%;
		width: 90px;
		height: 90px;
		opacity: 0.1;
	}

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 2;
		transform: translate3d(-50%, -50%, 0);
		display: block;
		flex: none;
		width: 24px;
		height: 24px;
		fill: $color-white;
	}
}
