.hero-contacts {
	.hero__title {
		margin-top: 0;

		.is-browser-ie & {
			margin-top: 140px;
		}
	}
}

.contacts-info {
	position: relative;
	z-index: 2;
	margin: 104px auto 186px;
	max-width: 1200px;

	.heading {
		margin-bottom: 56px;

		&__number {
			top: -200px;
		}

		&__desc {
			margin-top: 24px;
			max-width: 660px;
		}
	}

	&__detail {
		display: flex;
		justify-content: space-between;
		margin: auto;
		padding: 40px 131px 40px 92px;
		max-width: 828px;
		background-color: #f7f7f7;
		transform: translate3d(45px, 50px, 0);

		&[data-aos^="fade"][data-aos^="fade"].aos-animate {
			transform: translate3d(45px, 0, 0);
		}
	}

	&__det {
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 34px;
		line-height: 1.06;
		color: #162b32;
	}

	&__desc {
		margin-top: 6px;
		font-size: 16px;
		line-height: 1.75;
		color: rgba(#162b32, 0.8);
	}
}

.contacts-map {
	margin: 186px auto 128px;

	.heading {
		margin-bottom: 56px;

		&__desc {
			max-width: 660px;
		}
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}

	&__addresses {
		position: relative;
		margin: auto;
		margin-bottom: 70px;
		padding-bottom: 98px;
		max-width: 1104px;

		.swiper-wrapper {
			align-items: stretch;
		}

		&__nav {
			position: absolute;
			left: 0;
			bottom: 0;
			display: flex;
			align-items: center;
		}

		&.is-noslider {
			margin-bottom: 0;
			padding-bottom: 0;

			.contacts-map__addresses__nav {
				display: none;
			}

			.contacts-address {
				width: 50%;
			}
		}
	}

	&__container {
		position: relative;
		margin-top: 60px;
		height: 460px;
		background-color: #f7f7f7;

		&__inner {
			width: 100%;
			height: 100%;
		}
	}
}

.contacts-address {
	display: flex;
	flex: none;
	align-items: center;
	border-radius: 4px;
	padding: 40px 48px 48px 50px;
	height: auto;
	box-sizing: border-box;
	background-color: #f7f7f7;
	cursor: pointer;

	&__icon {
		flex: none;
		margin-right: 40px;
		width: 47px;
		height: 62px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__name {
		margin-bottom: 8px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 34px;
		line-height: 1.06;
		color: #162b32;
	}

	&__link {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		font-size: 16px;
		line-height: 1.75;
		text-decoration: none;
		color: rgba(#0e2026, 0.8);
		transition: color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

		span {
			display: inline-block;
			border-bottom: dotted 1px rgba(#0e2026, 0.6);
			transition: border-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
		}
	}

	.is-device-desktop &:hover {
		.contacts-address__link {
			color: #162b32;

			span {
				border-color: #162b32;
			}
		}
	}

	&:first-child {
		margin-right: 16px;
	}
}

.contacts-ask {
	margin: 128px auto 224px;
	max-width: 1200px;

	.heading {
		margin-bottom: 48px;

		&__desc {
			margin-top: 20px;
		}
	}

	.mediarow {
		&__col1 {
			margin-top: 145px;

			&::before {
				top: -145px;
			}
		}
	}
}

.contacts-form {
	label {
		margin-bottom: 16px;
	}

	input {
		height: 72px;
	}

	input,
	textarea {
		background-color: #f7f7f7;
	}

	.button {
		margin-top: 32px;
		width: 100%;
		max-width: 100%;
	}

	.form__agreement {
		color: rgba(#162b32, 0.5);

		a {
			color: #162b32;
		}
	}
}

.contacts-social {
	margin: 224px auto 120px;
	max-width: 1200px;

	&__row {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		margin: auto;
		max-width: 1104px;
		perspective: 500px;
	}

	&__item {
		position: relative;
		display: block;
		flex: none;
		width: calc(33.33333% - 12px);
		text-decoration: none;
		background-color: transparent;
		transform-style: preserve-3d;
		transform: translate3d(0, 0, 0);

		.inner-bg {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			border-radius: 2px;
			background-color: #f7f7f7;
			transform: translate3d(0, 0, 0);
		}

		.inner-depth {
			position: relative;
			z-index: 2;
			padding: 110px 50px 60px;
			width: 100%;
			height: 100%;
		}

		&:not(:last-child) {
			margin-right: 24px;
		}

		&--vk {
			.inner-bg {
				background-image: linear-gradient(224deg, #518bcf, #336aaa);
			}
		}

		&--fb {
			.inner-bg {
				background-image: linear-gradient(238deg, #567bc7, #3a61b0);
			}
		}

		&--inst {
			.inner-bg {
				background-image: url("../images/bg-instagram.png");
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}

	&__icon {
		position: absolute;
		left: 50px;
		top: 50px;
		z-index: 2;
		width: 24px;
		height: 24px;

		svg {
			display: block;
			width: 100%;
			height: 100%;
			fill: #fff;
		}
	}

	&__name {
		position: relative;
		z-index: 2;
		font-family: $font-franklingothic;
		font-size: 42px;
		line-height: 1;
		color: $color-white;
	}

	&__desc {
		position: relative;
		z-index: 2;
		margin-top: 27px;
		font-size: 16px;
		line-height: 1.75;
		color: $color-white;
	}
}
