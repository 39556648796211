.button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	outline: none;
	margin: auto;
	border: none;
	border-radius: 2px;
	padding: 0;
	width: 100%;
	height: 73px;
	max-width: 970px;
	box-shadow: none;
	font-family: $font-franklingothic;
	font-weight: 300;
	font-size: 23px;
	line-height: 0.52;
	letter-spacing: -0.1px;
	text-align: center;
	text-decoration: none;
	color: #202224;
	background-color: #fabd14;
	transition: color 0.3s ease;

	> span {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		display: flex;
		flex: none;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		transform: translateY(0%);
		transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
	}

	svg {
		position: relative;
		z-index: 2;
		display: block;
		margin-right: 20px;
		width: 20px;
		height: 20px;
		fill: #e6cd77;
		transform: translateZ(0);
		transition: fill 0.3s ease;
	}

	.helper-cont {
		transform: translate3d(0, 100%, 0);
	}

	> .button-shim {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		display: block;
		flex: none;
		width: 100%;
		height: 100%;
		background-color: #ffc935;
		transform: translate3d(0, 100%, 0);
		transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition-delay: 0.1s;
	}

	.is-device-desktop &:hover {
		.main-cont {
			transform: translate3d(0, -100%, 0);
		}

		.button-shim {
			transform: translate3d(0, 0, 0);
		}

		.helper-cont {
			transform: translate3d(0, 0, 0);
		}
	}

	&--white {
		background-color: $color-white;

		.button-shim {
			background-color: #f7f7f7;
		}
	}

	&--gray {
		background-color: #f7f7f7;

		.button-shim {
			background-color: #eceded;
		}
	}

	&--red {
		color: $color-white;
		background-color: #c3122f;

		.button-shim {
			background-color: #d52340;
		}
	}
}

.js-hover-btn {
	overflow: hidden;
	outline: none;

	> span {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
	}

	.button-shim {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		transform: translate3d(0, 100%, 0);
		transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition-delay: 0.1s;
	}

	.helper-cont {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		transform: translate3d(0, 100%, 0);
	}

	.is-device-desktop &:hover {
		.main-cont {
			transform: translate3d(0, -100%, 0);
		}

		.button-shim {
			transform: translate3d(0, 0, 0);
		}

		.helper-cont {
			transform: translate3d(0, 0, 0);
		}
	}

	.is-device-desktop &.is-current:hover {
		.main-cont {
			transform: translate3d(0, 0, 0);
		}

		.button-shim {
			transform: translate3d(0, 100%, 0);
		}

		.helper-cont {
			transform: translate3d(0, 100%, 0);
		}
	}
}

.js-icon-btn {
	overflow: hidden;

	svg {
		transform: translateZ(0);
	}

	&__inner {
		position: relative;
		z-index: 2;
		flex: none;
		overflow: hidden;
		width: 24px;
		height: 24px;
	}

	&__iconcontainer {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: flex-start;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	&__shim {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: #eceded;
		transform: translateY(100%);
	}
}
