.stats {
	display: flex;
	justify-content: center;
	margin: 170px auto 0;

	&__item {
		flex: none;
		margin: 0 46px;
		width: 200px;
	}

	&__row {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}

	&__num {
		font-family: $font-bebasneue;
		font-size: 220px;
		line-height: 0.7;
		letter-spacing: -5px;
		color: #d52340;
	}

	&__tip {
		margin-left: calc(1em + 15px);
		font-family: $font-franklingothic;
		font-size: 20px;
		line-height: 0.7;
		letter-spacing: 1.6px;
		text-transform: uppercase;
		color: #d52340;
		transform: rotate(-90deg);
		transform-origin: 0 100%;

		.is-os-mac &,
		.is-os-ios & {
			transform: rotate(-90deg) translateX(7px);
			transform-origin: 0 0;
		}
	}

	&__desc {
		margin-top: 33px;
		font-size: 15px;
		line-height: 1.6;
		color: #162b32;
	}
}

.inner-stats {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 0 -95px 68px;
	max-width: 980px;

	&__item {
		flex: none;
		margin: 0 0 95px;
		width: 200px;

		&:nth-child(1) {
			width: 340px;

			.inner-stats__tip {
				margin-left: calc(1em + 20px);
			}
		}

		&:nth-child(2) {
			width: 260px;

			.inner-stats__tip {
				margin-left: calc(1em + 30px);
			}
		}

		&:nth-child(3) {
			width: 200px;
		}

		&:nth-child(4) {
			margin-right: 60px;
			margin-left: -20px;

			.inner-stats__desc {
				padding-left: 20px;
			}
		}
	}

	&__row {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}

	&__num {
		font-family: $font-bebasneue;
		font-size: 220px;
		line-height: 0.7;
		letter-spacing: -5px;
		color: #d52340;
	}

	&__tip {
		margin-left: calc(1em + 15px);
		font-family: $font-franklingothic;
		font-size: 20px;
		line-height: 0.7;
		letter-spacing: 1.6px;
		text-transform: uppercase;
		color: #d52340;
		transform: rotate(-90deg);
		transform-origin: 0 100%;

		.is-os-mac &,
		.is-os-ios & {
			transform: rotate(-90deg) translateX(7px);
			transform-origin: 0 0;
		}
	}

	&__desc {
		margin-top: 25px;
		font-size: 15px;
		line-height: 1.6;
		color: #162b32;
	}
}
