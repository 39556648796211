.park-futuro {
	.heading__title,
	.inner-stats__num,
	.inner-stats__tip,
	.block-slider__num {
		color: $color-futuro;
	}

	.heading__number::before,
	.block-slider__dot,
	.tabs__btn.is-current,
	.video-play-btn {
		background-color: $color-futuro;
	}

	.video-play-btn {
		.button-shim {
			background-color: #46c9dc;
		}
	}

	.progress-select__item.is-current::after {
		background-color: $color-futuro;
	}

	.is-device-desktop & {
		.form label:hover .label-like {
			color: $color-futuro;
		}

		.files-slider__item:hover {
			background-color: #25b7cc;
		}
	}
}

.park-fonte {
	.heading__title,
	.inner-stats__num,
	.inner-stats__tip,
	.block-slider__num {
		color: $color-fonte;
	}

	.heading__number::before,
	.block-slider__dot,
	.tabs__btn.is-current,
	.video-play-btn {
		background-color: $color-fonte;
	}

	.video-play-btn {
		.button-shim {
			background-color: #24ac94;
		}
	}

	.progress-select__item.is-current::after {
		background-color: $color-fonte;
	}

	.is-device-desktop & {
		.form label:hover .label-like {
			color: $color-fonte;
		}

		.files-slider__item:hover {
			background-color: #1d9b85;
		}
	}
}

.park-avenue {
	.heading__title,
	.inner-stats__num,
	.inner-stats__tip,
	.block-slider__num {
		color: $color-avenue;
	}

	.heading__number::before,
	.block-slider__dot,
	.tabs__btn.is-current,
	.video-play-btn {
		background-color: $color-avenue;
	}

	.video-play-btn {
		.button-shim {
			background-color: #0abdbb;
		}
	}

	.progress-select__item.is-current::after {
		background-color: $color-avenue;
	}

	.is-device-desktop & {
		.form label:hover .label-like {
			color: $color-avenue;
		}

		.files-slider__item:hover {
			background-color: #0abdbb;
		}
	}
}
