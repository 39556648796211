.header {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 4;
	display: flex;
	align-items: center;
	padding-right: 48px;
	width: 100%;
	transform: translateY(-140px);

	&__logo {
		position: relative;
		z-index: 4;
		display: flex;
		flex: none;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		width: 228px;
		height: 88px;
		background-color: $color-accent-red;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: #c3122f;
			transform: translateY(100%);
			transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		svg {
			position: relative;
			z-index: 2;
			display: block;
			width: 122px;
			height: 55px;
			fill: $color-white;
			transform: translateZ(0);
		}

		.is-device-desktop &:hover {
			&::before {
				transform: translateY(0);
			}
		}
	}

	&__contacts {
		position: relative;
		z-index: 3;
		margin-top: auto;
		margin-left: auto;
	}

	&__phone {
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 26px;
		line-height: 1.15;
		text-align: right;
		color: $color-white;

		.page-error & {
			color: #162b32;
		}
	}

	&__time {
		font-weight: 300;
		font-size: 15px;
		line-height: 1.6;
		color: rgba($color-white, 0.7);

		.page-error & {
			color: rgba(#162b32, 0.8);
		}
	}

	&__progress {
		position: absolute;
		left: 356px;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2;
		display: none;
		flex: none;
		height: 100%;
		background-color: #283b41;
		transform: scaleX(0);
		transform-origin: 0 0;
	}

	&__scrollbtn {
		position: relative;
		z-index: 3;
		display: none;
		align-items: center;
		margin-left: auto;
		outline: none;
		border: none;
		height: 100%;
		box-shadow: none;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 1.6px;
		text-align: center;
		text-transform: uppercase;
		color: $color-white;
		background-color: transparent;
		transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

		svg {
			display: block;
			margin-right: 16px;
			width: 28px;
			height: 14px;
			fill: $color-white;
			transform: rotate(180deg);
			transition: fill 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

			.is-os-mac & {
				margin-top: -2px;
			}
		}

		.is-device-desktop &:hover {
			color: rgba($color-white, 0.4);

			svg {
				fill: rgba($color-white, 0.4);
			}
		}
	}

	&__call {
		position: relative;
		z-index: 3;
		display: none;
		align-items: center;
		justify-content: center;
		order: 3;
		margin-left: auto;
		width: 72px;
		height: 100%;

		svg {
			display: block;
			flex: none;
			width: 24px;
			height: 24px;
			fill: $color-white;
		}
	}

	&.is-shown {
		transform: translateY(0);
	}

	&.is-fixed {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 10;
		padding-right: 32px;
		height: 72px;
		box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
		background-color: #162b32;
		animation: showHeader 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;

		.header__logo {
			width: 197px;
			height: 71px;
			background-color: #aa132c;

			svg {
				width: 101px;
				height: 44px;
			}
		}

		.menu-btn {
			height: 72px;
		}

		.header__contacts {
			margin-top: 0;
			margin-left: 40px;
		}

		.header__phone {
			font-size: 19px;
			line-height: 0.53;
			letter-spacing: 0.1px;
		}

		.header__time {
			display: none;
		}

		.header__scrollbtn {
			display: flex;
		}

		.header__progress {
			left: 343px;
			display: block;
		}

		&.is-hiding {
			animation: hideHeader 0.2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
		}
	}

	.is-menu-opened & {
		position: fixed;
	}

	.page-error & {
		transform: none;
	}
}

.menu-btn {
	position: relative;
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	width: 146px;
	height: 88px;
	font-family: $font-franklingothic;
	font-size: 18px;
	line-height: 1;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: $color-white;
	background-color: #162b32;
	cursor: pointer;

	> span {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.button-shim {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: #283b41;
		transform: translate3d(0, 100%, 0);
	}

	&__text {
		margin-top: 2px;
	}

	&__lines {
		display: block;
		margin-right: 16px;
		width: 26px;
	}

	&__line {
		display: block;
		height: 2px;
		background-color: $color-white;

		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
}

.menu {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 20;
	display: none;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	&-bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;

		&__col1 {
			flex: none;
			width: 50%;
			height: 100%;
			background-color: transparent;

			.image {
				width: 100%;
				height: 100%;
				background-color: #c3122f;
				opacity: 0;
			}
		}

		&__col2 {
			position: relative;
			flex: none;
			width: 50%;
			height: 100%;
			background-color: transparent;

			&__mask {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 0;
				width: 100%;
				height: 100%;
				background-color: #f7f7f7;
				transform: scaleY(0) translateZ(0);
				transform-origin: 0 100%;
			}

			.image {
				position: absolute;
				left: 0;
				right: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				background-position: center;
				background-size: auto 100%;
				background-repeat: no-repeat;
				opacity: 0;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(30, 33, 40, 0.73);
				}
			}
		}
	}

	&-icon {
		position: absolute;
		z-index: 3;
		display: flex;
		width: 840px;
		height: 420px;

		&__col1 {
			overflow: hidden;
			width: 50%;

			svg {
				width: 200%;
				height: 100%;
				fill: #0e2026;
				opacity: 0.03;
			}

			use {
				width: 100%;
				height: 100%;
			}
		}

		&__col2 {
			width: 50%;

			svg {
				width: 100%;
				height: 100%;
				fill: #0e2026;
				opacity: 0.03;
			}

			use {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__head {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 72px;
		transform: translateY(-100%);
	}

	&__close {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		border: none;
		padding: 0;
		width: 146px;
		height: 72px;
		box-shadow: none;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		color: $color-white;
		background-color: #162b32;
		transition:
			background-color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1),
			color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

		span {
			position: relative;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		svg {
			display: block;
			flex: none;
			margin-right: 12px;
			width: 18px;
			height: 18px;
			fill: $color-white;
			transition: fill 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		.button-shim {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: #283b41;
			transform: translate3d(0, 100%, 0);
		}

		.is-col1-hovered & {
			color: #162b32;
			background-color: $color-white;

			svg {
				fill: #162b32;
			}
		}
	}

	&__logo {
		position: relative;
		z-index: 4;
		display: flex;
		flex: none;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		width: 197px;
		height: 72px;
		background-color: $color-accent-red;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: #c3122f;
			transform: translateY(100%);
			transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		svg {
			position: relative;
			z-index: 2;
			display: block;
			width: 101px;
			height: 44px;
			fill: $color-white;
		}

		.is-device-desktop &:hover {
			&::before {
				transform: translateY(0);
			}
		}
	}

	&__inner {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin: auto;
		width: 100%;
		height: 0;
		background-color: $color-white;
		transform: translateZ(0);
	}

	&__content {
		position: relative;
		z-index: 5;
		display: flex;
		width: 840px;
		min-height: 420px;
	}

	&__col {
		position: relative;
		z-index: 3;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: 50%;
		text-align: left;

		a {
			position: relative;
			display: inline-block;
			font-family: $font-franklingothic;
			font-weight: 300;
			font-size: 34px;
			line-height: 1;
			text-decoration: none;
			color: #162b32;
			opacity: 0;
			transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

			&:not(:last-child) {
				margin-bottom: 64px;
			}

			.is-device-desktop &:hover {
				color: #d52340;
			}

			&.is-current {
				&::before {
					content: "";
					position: absolute;
					right: calc(100% + 40px);
					top: 0.5em;
					display: block;
					border-radius: 50%;
					width: 6px;
					height: 6px;
					background-color: #aa132c;
					transition: background-color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
				}
			}
		}

		&.is-col-hovered {
			a.is-current::before {
				background-color: $color-white;
			}

			a {
				color: $color-white;
			}
		}

		&--1 {
			padding-left: 127px;

			a {
				.is-device-desktop &:hover {
					color: #850f22;

					&::before {
						background-color: #850f22;
					}
				}
			}
		}

		&--2 {
			padding-left: 164px;

			a {
				.is-device-desktop &:hover {
					color: rgba($color-white, 0.4);

					&::before {
						background-color: rgba($color-white, 0.4);
					}
				}
			}
		}
	}

	&__info {
		position: absolute;
		right: 56px;
		bottom: 56px;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		color: rgba(#162b32, 0.8);
		opacity: 0;
		transform: translateY(80px);
		transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

		a {
			margin-left: 4px;
			text-decoration: none;
			color: #c3122f;
			transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

			.is-device-desktop &:hover {
				color: #162b32;
			}
		}

		.is-col2-hovered & {
			color: rgba($color-white, 0.7);

			a {
				color: $color-white;
			}
		}
	}

	&__social {
		position: absolute;
		left: 56px;
		bottom: 56px;
		z-index: 2;
		opacity: 0;
		transform: translateY(80px);

		.socials {
			svg {
				fill: #162b32;
				transition:
					opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1),
					fill 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

				.is-col1-hovered & {
					fill: $color-white;
				}
			}
		}
	}
}

@keyframes showHeader {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: none;
	}
}

@keyframes hideHeader {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}
