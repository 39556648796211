.form {
	width: 100%;
	transition: opacity 0.3s ease;

	.heading {
		margin-bottom: 72px;

		&__desc {
			margin-top: 20px;
		}
	}

	&__title {
		margin-bottom: 20px;
		font-weight: 300;
		font-size: 24px;
		line-height: 1.33;
		color: $color-white;
	}

	&__subtitle {
		margin-bottom: 40px;
		font-weight: 300;
		font-size: 14px;
		line-height: 1.71;
		letter-spacing: 0.3px;
		color: $color-white;
	}

	&__agreement {
		margin-top: 24px;
		font-size: 15px;
		line-height: 1.6;
		color: rgba($color-white, 0.5);

		a {
			text-decoration: none;
			color: $color-white;
			transition: color 0.3s ease;

			.is-device-desktop &:hover {
				color: #f2aa0f;
			}
		}

		.form__radio {
			align-items: center;
			margin-bottom: 0;

			.is-device-desktop &:hover {
				.form__radio__label {
					color: inherit;
				}
			}
		}

		.form__radio__label {
			font-family: $font-opensans;
			font-size: 15px;
			line-height: 1.6;
			color: inherit;

			a {
				color: #fff;

				&:hover {
					color: #f2aa0f;
				}
			}
		}
	}

	h2 {
		.-form & {
			margin-bottom: 56px;
			max-width: 440px;
		}

		.-consultform & {
			margin-bottom: 24px;
			line-height: 1;
		}
	}

	p {
		margin-top: 0;
	}

	label {
		position: relative;
		display: block;
		margin-bottom: 20px;
		width: 100%;

		.is-device-desktop &:hover .label-like {
			color: #f2aa0f;
		}

		&.is-required {
			&::before {
				content: "";
				position: absolute;
				right: 14px;
				top: 14px;
				display: block;
				border-radius: 50%;
				width: 8px;
				height: 8px;
				background-color: #d6dcde;
			}
		}
	}

	input {
		outline: none;
		border: 2px solid transparent;
		border-radius: 2px;
		padding: 16px 20px 0;
		width: 100%;
		height: 73px;
		font-size: 16px;
		background-color: $color-white;

		&:focus ~ .label-like {
			top: 18px;
			font-size: 12px;
		}
	}

	textarea {
		outline: none;
		border: 2px solid transparent;
		border-radius: 2px;
		padding: 42px 20px 0;
		width: 100%;
		height: 144px;
		font-size: 16px;
		resize: none;

		&:focus ~ .label-like {
			top: 18px;
			font-size: 12px;
		}
	}

	button {
		border: none;
		border-radius: 2px;
		width: 100%;
		height: 72px;
		box-shadow: none;
		font-family: $font-franklingothic;
		font-size: 23px;
		line-height: 0.52;
		letter-spacing: -0.1px;
		text-align: center;
		color: #202224;
		background-color: #fabd14;
	}

	.label-like {
		position: absolute;
		left: 20px;
		top: 28px;
		font-family: $font-opensans;
		font-weight: 300;
		font-size: 16px;
		line-height: 1;
		color: rgba(#162b32, 0.5);
		transition: all 0.2s ease;
		pointer-events: none;

		&.is-inputfilled {
			top: 18px;
			font-size: 12px;
		}
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 -4px;

		label {
			margin: 0 4px;
			width: 310px;

			.call & {
				&:nth-child(2) {
					flex: none;
					width: 200px;
				}
			}
		}

		button {
			margin: 0 4px;
			width: 310px;
		}

		.-form & {
			label {
				&:nth-child(1) {
					width: 352px;
				}

				&:nth-child(2) {
					width: 284px;
				}
			}
		}

		.-consultform & {
			margin-top: 64px;

			label {
				&:nth-child(1) {
					width: 352px;
				}

				&:nth-child(2) {
					width: 284px;
				}
			}
		}
	}

	&__modalrow {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;

		.form__agreement {
			margin: 0;
			max-width: 300px;
			color: rgba(#162b32, 0.5);

			a {
				color: #0e2026;
			}
		}

		.button {
			margin: 0;
			max-width: 481px;

			.is-article & {
				max-width: 392px;
			}

			.-consultform &,
			.-form & {
				max-width: 284px;
			}
		}
	}

	&__desc {
		margin-top: 30px;
		font-weight: 300;
		font-size: 12px;
		line-height: 1.83;
		letter-spacing: 0.3px;
		color: #aaadb9;

		a {
			text-decoration: none;
			color: $color-white;
		}
	}

	&__radiorow {
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		.form__radio {
			margin-right: 70px;
		}

		.-form & {
			margin-bottom: 32px;

			.form__radio {
				margin-right: 55px;
				margin-bottom: 0;
			}
		}

		.page-park & {
			display: none;
		}
	}

	&__radio {
		position: relative;
		cursor: pointer;

		input {
			position: absolute;
			left: 0;
			top: 0;
			width: 0;
			height: 0;
			opacity: 0;

			&:checked ~ .form__radio__control {
				&::before {
					display: block;
				}
			}
		}

		&__control {
			position: relative;
			display: block;
			flex: none;
			border: 2px solid transparent;
			margin-right: 16px;
			border-radius: 2px;
			width: 20px;
			height: 20px;
			background-color: $color-white;
			cursor: pointer;

			&::before {
				content: "";
				position: absolute;
				left: 3px;
				top: 3px;
				z-index: 1;
				display: none;
				flex: none;
				border-radius: 1px;
				width: 10px;
				height: 10px;
				background-color: #ffba49;
			}

			.modal &,
			.contacts-form & {
				background-color: #f2f2f2;
			}
		}

		&__label {
			font-family: $font-franklingothic;
			font-size: 26px;
			line-height: 0.9;
			color: $color-white;
			transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

			.modal & {
				color: rgba(#162b32, 0.5);
			}
		}

		&.parsley-error {
			.form__radio__control {
				border-color: #e82847;
			}
		}

		.is-device-desktop &:hover {
			.form__radio__label {
				color: #ffba49;
			}
		}

		.form & {
			display: inline-flex;
			width: auto;
		}
	}

	&.is-pending {
		button {
			pointer-events: none;
		}
	}
}

.form-success-cont {
	display: none;
}

.form-success {
	padding: 84px 92px 92px;
	background-color: $color-white;

	&__btn {
		margin-top: 49px;
		margin-left: 0;
		max-width: 196px;
		background-color: #f7f7f7;

		&.button {
			margin-top: 49px;
			margin-left: 0;
			max-width: 196px;
			background-color: #f7f7f7;

			.button-shim {
				background-color: #eceded;
			}
		}
	}
}

.parsley-error {
	input,
	textarea {
		border-color: #e82847;
		color: #e82847;

		&:hover ~ .error-messages,
		&:focus ~ .error-messages {
			display: block;
		}
	}

	.form &.is-required::before {
		background-color: #e82847;
	}
}

.parsley-success {
	.error-messages {
		display: none;
	}
}

.error-messages {
	position: absolute;
	left: 0;
	top: calc(100% - 1px);
	z-index: 2;
	display: none;
	margin: 0;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
	padding: 19px 24px;
	min-width: 100%;
	background-color: #e82847;

	li {
		list-style: none;
		font-size: 14px;
		line-height: 1.2;
		color: $color-white;
	}
}
