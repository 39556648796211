.index-about {
	margin: 153px auto 232px;
	max-width: 1200px;

	.heading {
		&__text {
			max-width: 562px;
		}
	}

	.slider-pagination {
		justify-content: center;
		margin-top: 45px;
	}

	&__videoblock {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		margin: auto;
		max-width: 1104px;
	}

	&__video {
		position: relative;
		flex: none;
		margin-right: 92px;
		width: 736px;
		height: 414px;
		background: url("../images/index-about.jpg") center no-repeat;
		background-size: cover;
	}

	&__videodesc {
		flex: 1;
		padding-top: 80px;
	}

	@media (max-width: 1270px) {
		margin-bottom: 152px;
	}
}

.video-preview {
	overflow: hidden;
	width: 100%;
	height: 100%;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.is-inited & {
		display: none;
	}
}

.video-container {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	overflow: hidden;
	width: 100%;
	height: 100%;

	iframe {
		width: 100%;
		height: 100%;
	}
}

.video-play-btn {
	position: absolute;
	left: 40px;
	bottom: 40px;
	z-index: 3;
	overflow: hidden;
	border: none;
	outline: none;
	border-radius: 2px;
	width: 72px;
	height: 72px;
	box-shadow: none;
	background-color: #c3122f;

	.button-shim {
		background-color: #d52340;
	}

	span {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	svg {
		display: block;
		flex: none;
		width: 24px;
		height: 24px;
		fill: $color-white;
	}

	.is-inited & {
		display: none;
	}
}

.index-arch {
	margin: 232px auto 228px;
	max-width: 1200px;

	.button {
		display: none;
		margin-top: 16px;
	}

	@media (max-width: 1270px) {
		overflow: hidden;
		margin-top: 152px;
		margin-bottom: 96px;
		padding-top: 80px;
		padding-bottom: 72px;
	}
}

.index-map {
	margin: 228px auto 192px;

	.heading {
		margin-right: auto;
		margin-bottom: 80px;
		margin-left: auto;
		max-width: 1200px;
	}

	.parks-map {
		width: 100%;
		height: 100%;
	}

	.map-goto {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		border-radius: 2px;
		width: 320px;
		height: 73px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 23px;
		line-height: 0.52;
		letter-spacing: -0.1px;
		text-align: center;
		text-decoration: none;
		// color: #202224;
		color: #ffffff;
		// background-color: $color-white;
		background-color: #162b32;

		> span {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}

		.button-shim {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			// background-color: #f1f1f1;
			background-color: #283b41;
			transform: translate3d(0, 100%, 0);
		}
	}

	&__addresses {
		display: none;
	}

	&__container {
		position: relative;
		width: 100%;
		height: 680px;
		background-color: #f7f7f7;

		&__inner {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 104px;
			z-index: 3;
			margin: auto;
			height: 0;
			max-width: 1104px;

			.is-browser-ie & {
				width: 1104px;
			}
		}
	}
}

.map-places {
	display: none;
}

.poi-marker {
	&:hover {
		background-image: url("../images/map-markers/poi-hov.svg") !important;
	}
}

.infoBox {
	padding-bottom: 30px;
	max-width: 172px;
	box-sizing: border-box;
	background: url("../images/map-markers/marker-opened.svg") center bottom no-repeat;
	transform: translate3d(-50%, 0, 0) !important;

	&__close {
		position: absolute;
		right: 4px;
		top: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		width: 24px;
		height: 24px;
		background-color: $color-white;
		cursor: pointer;

		img {
			display: block;
			flex: none;
			width: 10px;
			height: 10px;
		}

		.is-device-desktop &:hover {
			background-color: #f7f7f7;
		}
	}
}

.infobox-cont {
	overflow: hidden;
	border-radius: 4px;
	width: 100%;
	box-sizing: border-box;
	background-color: $color-white;

	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 108px;

		img {
			display: block;
			flex: none;
			margin: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		margin-top: 15px;
		padding: 0 15px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 19px;
		line-height: 1;
		letter-spacing: 0.1px;
		color: #202224;
	}

	&__desc {
		margin-top: 2px;
		padding: 0 15px;
		font-size: 12px;
		line-height: 1.5;
		color: rgba(22, 43, 50, 0.5);
	}
}

.map-nav {
	position: absolute;
	left: 0;
	right: 0;
	top: 68px;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: auto;
	height: 0;
	max-width: 1104px;

	&__btn {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin-right: 4px;
		border: none;
		border-radius: 2px;
		width: 172px;
		height: 72px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 23px;
		line-height: 0.52;
		letter-spacing: -0.1px;
		text-align: center;
		color: $color-white;
		background-color: #162b32;

		> span {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}

		.button-shim {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: #283b41;
			transform: translate3d(0, 100%, 0);
		}

		&.is-current {
			background-color: #c3122f;

			.button-shim {
				display: none;
			}
		}
	}

	.is-browser-ie & {
		width: 1104px;
	}
}

.map-controls {
	position: absolute;
	right: 32px;
	bottom: 32px;
	z-index: 4;

	&__btn {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		width: 72px;
		height: 72px;
		box-shadow: none;
		background-color: $color-white;

		.button-shim {
			background-color: #f1f1f1;
		}

		svg {
			display: block;
			width: 24px;
			height: 24px;
		}

		&--zoomin {
			margin-bottom: 4px;
		}
	}
}

.index-advantages {
	margin: 192px auto 232px;
	max-width: 1200px;

	.heading {
		&__number {
			height: 942px;
		}

		&__text {
			max-width: 800px;
		}
	}

	.big-slider__nav {
		left: auto;
		right: 0;
	}

	.slider-pagination {
		margin-right: 40px;
		margin-left: 0;
	}

	.button {
		display: none;
		margin-top: 16px;
	}
}

.index-features {
	overflow: hidden;
	padding-top: 137px;
	padding-bottom: 224px;

	.heading {
		&__number {
			top: -138px;
		}

		&__text {
			max-width: 726px;
		}

		&__desc {
			max-width: 528px;
		}
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}

.call {
	position: relative;
	padding-top: 144px;
	padding-bottom: 158px;
	background: url("../images/index-call.jpg") center no-repeat;
	background-size: cover;

	.heading {
		&__content {
			margin-left: 0;
		}
	}

	.form {
		.heading {
			&__title {
				color: $color-white;
			}
		}
	}

	.form-success-cont {
		display: none;
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		overflow: hidden;
		width: 100%;
		height: 100%;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(30, 33, 40, 0.73);
		}

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__form {
		position: relative;
		z-index: 2;
		margin: auto;
		max-width: 1104px;
	}

	@media (max-width: 1200px) {
		overflow: hidden;
	}
}

.index-offers {
	overflow: hidden;
	margin: 112px auto 212px;
	padding-top: 80px;

	.heading {
		margin-bottom: 190px;

		&__text {
			max-width: 546px;
		}
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}

.index-conditions {
	margin: 212px auto 232px;
	max-width: 1200px;
}

.index-conditions2 {
	overflow: hidden;
	padding: 72px 30px 64px;
	background-color: #f7f7f7;

	.heading {
		&__content {
			margin-left: 0;
		}
	}

	.button {
		margin-top: 94px;
		margin-left: 0;
	}

	.swiper-container {
		overflow: visible;
		margin-left: 0;
		max-width: 278px;
	}

	&__inner {
		max-width: 1200px;
	}

	&__item {
		padding: 52px 40px 40px;
		box-sizing: border-box;
		background-color: #0e2026;
		transition: background-color 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

		&.swiper-slide-active {
			background-color: $color-white;
		}
	}
}

.mediarow {
	display: flex;
	margin: auto;
	max-width: 1104px;

	.heading {
		margin-bottom: 75px;

		&__content {
			margin-left: 0;
		}
	}

	&__col1 {
		position: relative;
		flex: none;
		margin-top: 40px;
		margin-right: 124px;
		width: 427px;
		height: 600px;

		&::before {
			content: "";
			position: absolute;
			left: -92px;
			top: -95px;
			overflow: hidden;
			border-radius: 2px;
			width: 184px;
			height: 348px;
			background-color: #d52340;
		}
	}

	&__col2 {
		flex: 1;
		width: 100%;

		.button {
			margin-left: 0;
			max-width: 366px;
		}
	}

	&__media {
		position: relative;
		z-index: 2;
		overflow: hidden;
		border-radius: 2px;
		width: 100%;
		height: 100%;
		// background: url("../images/mediarow.jpg") center no-repeat;
		// background-size: cover;

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__stats {
		display: flex;
		margin-top: auto;
		margin-bottom: 96px;
	}

	&__statitem {
		flex: none;
		margin-right: 64px;
		max-width: 133px;
	}

	&__num {
		font-family: $font-franklingothic;
		font-size: 26px;
		line-height: 1;

		b {
			font-weight: 300;
			font-size: 68px;
		}
	}

	&__numtip {
		margin-top: 10px;
		font-size: 15px;
		line-height: 1.6;
	}
}

.index-contacts {
	position: relative;
	margin: 232px auto 168px;
	max-width: 1200px;

	.heading {
		&__text {
			max-width: 581px;
		}

		&__desc {
			max-width: 537px;
		}
	}

	&__row {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		margin: auto;
		max-width: 1104px;
	}

	&__cont {
		flex: 1;
		max-width: 190px;
		font-size: 15px;
		line-height: 1.6;
		color: #162b32;

		h2 {
			margin-bottom: 24px;
			opacity: 1;
		}

		a,
		button, {
			display: block;
			margin-top: 17px;
			border: none;
			padding: 0;
			box-shadow: none;
			font-size: 16px;
			line-height: 1.75;
			text-align: left;
			text-decoration: none;
			color: #0e2026;
			background-color: transparent;
			opacity: 0.8;
			transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

			span {
				display: inline-block;
				border-bottom: 1px dotted rgba(#0e2026, 0.6);
			}

			.is-device-desktop &:hover {
				color: #162b32;
				opacity: 1;

				span {
					border-color: #162b32;
				}
			}
		}
	}

	&__block {
		&:not(:first-child) {
			margin-top: 76px;
		}
	}

	&__phone {
		margin-bottom: 16px;

		div {
			opacity: 0.8;
		}

		b {
			display: block;
			font-family: $font-franklingothic;
			font-weight: 300;
			font-size: 22px;
			line-height: 1.09;
			color: #162b32;
			opacity: 1;
		}
	}

	&__addr {
		display: none;
	}

	&__map {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 4;
		width: 100%;
		height: 100%;
		opacity: 0;
		transform: translateZ(0);

		&__close {
			position: absolute;
			right: 24px;
			top: 24px;
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			border: none;
			border-radius: 2px;
			padding: 0;
			width: 56px;
			height: 56px;
			box-shadow: none;
			background-color: #0e2026;
			cursor: pointer;

			.button-shim {
				background-color: #162b32;
			}

			svg {
				position: relative;
				z-index: 2;
				display: block;
				width: 20px;
				height: 20px;
				fill: $color-white;
				transform: translateZ(0);
			}
		}

		&__container {
			width: 100%;
			height: 100%;
		}

		&.is-visible {
			opacity: 1;
			pointer-events: all;
		}
	}

	&__media {
		position: relative;
		width: 828px;
		height: 468px;

		&__shim {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
			width: 100%;
			height: 100%;
			background-color: #f7f7f7;
			transform: scaleY(0);
			transform-origin: 0 0;
			pointer-events: none;
		}

		&__bg {
			content: "";
			position: absolute;
			right: -72px;
			bottom: -72px;
			z-index: 0;
			display: block;
			border-radius: 2px;
			width: 348px;
			height: 348px;
			background-color: #f7f7f7;

			.before-like {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #d52340;
				transform: scaleY(0);
				transform-origin: 0 0;
			}

			@media (max-width: 1260px) {
				right: -45px;
			}
		}

		&__cont {
			position: relative;
			z-index: 2;
			overflow: hidden;
			width: 100%;
			height: 100%;

			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.is-device-desktop &:hover {
			.index-contacts__image__bg::before {
				transform: scaleY(1);
			}
		}
	}

	&__social {
		display: none;
		align-items: center;
		margin-top: 79px;

		a {
			display: block;
			opacity: 1;

			&:not(:last-child) {
				margin-right: 24px;
			}
		}

		svg {
			display: block;
			width: 30px;
			height: 30px;
			fill: $color-white;
		}
	}

	.page-park & {
		margin-top: 0;
	}
}
