.modal {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 40;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: auto;
	padding: 80px 0;
	background-color: rgba(30, 33, 40, 0.6);
	animation: opacShow 0.3s ease;

	&__cross {
		position: absolute;
		right: 32px;
		top: 32px;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		border: none;
		border-radius: 2px;
		padding: 0;
		width: 72px;
		height: 72px;
		box-shadow: none;
		background-color: #162b32;

		.button-shim {
			background-color: #283b41;
		}

		svg {
			position: relative;
			z-index: 2;
			display: block;
			width: 18px;
			height: 18px;
			fill: $color-white;
			transform: translateZ(0);
		}

		.modal.-video & {
			position: fixed;
			right: 40px;
			top: 40px;
		}
	}

	&__shim {
		display: none;
	}

	&__inner {
		position: relative;
		z-index: 2;
		margin: auto;
		width: 100%;
		max-width: 1024px;
		animation: zoomShow 0.3s ease;

		.modal.-agreement &,
		.modal.-policy &,
		.is-article & {
			max-width: 910px;
		}

		.modal.-consultform &,
		.modal.-form & {
			max-width: 964px;
		}

		.modal.-formsuccess & {
			max-width: 707px;
		}

		.modal.-video & {
			padding: 8px;
			width: 976px;
			height: 556px;
			background-color: #fff;
		}

		.is-browser-ie &,
		.is-browser-firefox & {
			padding-bottom: 80px;
		}
	}

	&__formstatus {
		flex: none;
		width: 136px;
		background-color: #f7f7f7;
		transition: background-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

		&__progress {
			width: 100%;
			height: 100%;
			background-color: #eceded;
			transform: scaleY(0);
			transform-origin: 0 0;
			transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		&.is-start {
			&--1 {
				.modal__formstatus__progress {
					transform: scaleY(0.3);
				}
			}

			&--2 {
				.modal__formstatus__progress {
					transform: scaleY(0.6);
				}
			}

			&--3 {
				.modal__formstatus__progress {
					transform: scaleY(1);
				}
			}
		}

		&.is-pending {
			background-color: #22d67c;

			.modal__formstatus__progress {
				transform: scaleY(0);
				transform-origin: 0 100%;
			}
		}
	}

	&.-video {
		.videoblock {
			width: 100%;
			height: 100%;
			border-radius: 4px;
			background-color: #ffffff;

			iframe {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}

	.is-browser-ie &,
	.is-browser-firefox & {
		padding-bottom: 0;
	}
}

.modal-more-container {
	padding-top: 44px;
	background-color: $color-white;
}

.modal-form {
	padding: 80px 68px 64px 64px;
	width: 100%;
	background-color: $color-white;

	.form-success-cont {
		display: none;
	}

	.form {
		input,
		textarea {
			background-color: #f7f7f7;
		}

		&__row {
			margin-bottom: 8px;
		}
	}

	.is-news & {
		display: none;
	}

	.modal.-consultform &,
	.modal.-form & {
		display: flex;
		align-items: stretch;
		padding: 0;

		.form {
			padding: 80px 92px;
			width: calc(100% - 136px);
		}
	}
}

.modal-formsuccess {
	display: flex;
	align-items: stretch;

	&__status {
		flex: none;
		width: 136px;
		background-color: #f7f7f7;

		&__progress {
			width: 100%;
			height: 100%;
			background-color: #d52340;
			transform: scaleY(0);
			transform-origin: 0 0;
			transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
		}
	}
}

.modal-props {
	&__heading {
		margin-bottom: 48px;
		padding-right: 170px;
		padding-left: 64px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 42px;
		line-height: 1.05;
		color: #162b32;
	}

	&__slider {
		position: relative;
		margin-bottom: 80px;
		height: 800px;
		background-color: #f7f7f7;

		.swiper-slide {
			padding: 43px 64px 56px;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
		}

		.slider-pagination {
			margin-right: 42px;
			margin-left: 0;
		}

		&__desc {
			position: absolute;
			left: 64px;
			bottom: 56px;
			z-index: 4;
			font-family: $font-franklingothic;
			font-weight: 300;
			font-size: 23px;
			line-height: 1;
			letter-spacing: -0.1px;
			color: #202224;
			transition: color 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

			.is-controls-white & {
				color: #f7f7f7;
			}
		}
	}

	&__img {
		margin: 0 auto 36px;
		width: 653px;
		height: 100%;

		img {
			display: block;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-right: 64px;
		padding-left: 64px;
	}

	&__item {
		display: flex;
		margin-bottom: 16px;
		width: 402px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 22px;
		line-height: 1.09;
		color: #162b32;

		&__name {
			white-space: nowrap;
			color: rgba(#162b32, 0.6);
		}

		&__dots {
			flex: 1;
			margin-right: 8px;
			margin-left: 7px;
			border-bottom: 1px dotted #738084;
			width: 100%;
			height: 24px;
		}

		&__val {
			flex: none;
			width: 119px;
			white-space: nowrap;
		}

		&--sum {
			margin-top: 27px;
			margin-bottom: 59px;

			.modal-props__item__val {
				color: #009e9b;
			}
		}
	}

	&__slidernav {
		position: absolute;
		right: 32px;
		bottom: 32px;
		z-index: 3;
		display: flex;
		align-items: center;
	}
}

.props-more {
	margin-right: 64px;
	margin-left: 64px;

	&__button {
		display: flex;
		justify-content: space-between;
		cursor: pointer;

		&__text {
			display: flex;
			flex: none;
			align-items: center;
			border-radius: 2px;
			padding-left: 32px;
			width: calc(100% - 74px);
			height: 72px;
			font-family: $font-franklingothic;
			font-weight: 300;
			font-size: 22px;
			line-height: 1;
			color: rgba(#202224, 0.6);
			background-color: #f7f7f7;
			transition: background-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		&__icon {
			display: flex;
			flex: none;
			align-items: center;
			justify-content: center;
			border-radius: 2px;
			width: 72px;
			height: 72px;
			background-color: #f7f7f7;
			transform: rotate(90deg);
			transition: background-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

			svg {
				display: block;
				flex: none;
				width: 24px;
				height: 24px;

				.is-opened & {
					transform: rotate(-180deg);
				}
			}
		}

		.is-device-desktop &:hover {
			.props-more__button__text {
				background-color: #eceded;
			}

			.props-more__button__icon {
				background-color: #eceded;
			}
		}
	}

	&__content {
		overflow: hidden;
		border-top: 2px solid $color-white;
		border-radius: 3px;
		width: 100%;
		height: 0;
		font-size: 15px;
		line-height: 1.6;
		color: rgba(#162b32, 0.8);
		background-color: #f7f7f7;
		transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

		.text {
			padding: 24px 154px 24px 32px;
		}
	}
}

.modal-article {
	h2 {
		padding-right: 0;
	}

	a {
		text-decoration: none;
		color: #c3122f;
	}

	.swiper-slide {
		box-sizing: border-box;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__slidernav {
		position: absolute;
		left: 32px;
		bottom: 32px;
		z-index: 5;
		display: flex;
		align-items: center;
	}

	&__slider {
		position: relative;
		margin-top: 64px;
		margin-bottom: 64px;
		width: 100%;
		height: 500px;

		.image-slider {
			width: 100%;
			height: 100%;
		}
	}

	&__wrapper {
		padding-right: 136px;
		padding-left: 64px;
	}

	&__date {
		margin-top: 17px;
		margin-bottom: 56px;
		padding-right: 120px;
		padding-left: 64px;
		font-family: $font-franklingothic;
		font-weight: 300;
		font-size: 20px;
		line-height: 0.7;
		letter-spacing: 1.6px;
		text-transform: uppercase;
		color: #162b32;
	}

	&__tip {
		margin-top: 40px;
		border-radius: 2px;
		padding: 40px 71px 40px 40px;
		font-size: 15px;
		line-height: 1.6;
		color: rgba(#162b32, 0.8);
		background-color: #f7f7f7;
	}

	&__share {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 80px;
		padding: 40px 64px;
		background-color: #f7f7f7;

		&__title {
			font-family: $font-franklingothic;
			font-weight: 300;
			font-size: 22px;
			line-height: 1.18;
			color: #162b32;
		}

		&__desc {
			margin-top: 4px;
			font-size: 15px;
			line-height: 1.6;
			color: rgba(#162b32, 0.8);
		}
	}
}

.modal-agreement {
	margin: auto;
	padding: 64px 136px 64px 64px;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.75;
	color: #162b32;
	background-color: $color-white;

	h2 {
		max-width: 530px;
	}

	p {
		margin: 30px 0;
	}

	ul {
		margin: 30px 0;
		padding-left: 32px;

		li {
			position: relative;
			list-style: none;
			margin-bottom: 10px;
			padding-left: 32px;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0.7em;
				border-radius: 50%;
				width: 8px;
				height: 8px;
				background-color: #eceded;
			}

			b {
				display: block;
				font-weight: 300;
				color: $color-white;
			}
		}
	}

	ol {
		counter-reset: item;
		padding-left: 0;

		li {
			display: block;
			margin-top: 48px;
			font-family: $font-franklingothic;
			font-weight: 300;
			font-size: 22px;
			line-height: 1.18;
			color: #162b32;

			&::before {
				counter-increment: item;
				content: counters(item, ".") ". ";
			}

			ol {
				padding-left: 0;
			}

			li {
				margin-top: 24px;
				font-family: $font-opensans;
				font-size: 16px;
				line-height: 1.75;
				color: rgba(#162b32, 0.8);
			}
		}

		ul {
			margin: 30px 0;
			padding-left: 32px;

			li {
				position: relative;
				list-style: none;
				margin-bottom: 10px;
				padding-left: 32px;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0.7em;
					border-radius: 50%;
					width: 8px;
					height: 8px;
					background-color: #eceded;
				}

				b {
					display: block;
					font-weight: 300;
					color: $color-white;
				}
			}
		}
	}

	&__buttonrow {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 40px;

		.button:not(:last-child) {
			margin-right: 10px;
		}
	}

	.button {
		display: inline-block;
		margin: 0;
		padding: 0 35px;
		width: auto;

		> span {
			position: static;
		}

		.helper-cont {
			position: absolute;
		}

		&--left {
			background-color: #e6cd77;
		}
	}
}

.sharing {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: -28px;

	&__item {
		display: flex;
		flex: none;
		align-items: center;
		justify-content: center;
		margin-right: 28px;
		outline: none;
		border: none;
		border-radius: 2px;
		padding: 0;
		width: 28px;
		height: 28px;
		box-shadow: none;

		svg {
			width: 100%;
			height: 100%;

			.bg-rect {
				transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
			}

			.bg-rect-hov {
				opacity: 0;
				transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
			}
		}

		.is-device-desktop &:hover {
			svg {
				.bg-rect {
					opacity: 0;
				}

				.bg-rect-hov {
					opacity: 1;
				}
			}
		}
	}
}

@keyframes opacShow {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes zoomShow {
	0% {
		transform: scale(0.8) translateZ(0);
	}

	100% {
		transform: scale(1) translateZ(0);
	}
}
