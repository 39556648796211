.about-info {
	margin: 215px auto;
	max-width: 1200px;

	.heading {
		&__desc {
			max-width: 600px;
		}
	}
}

.videoblock {
	position: relative;
	z-index: 2;
	display: flex;
	justify-content: space-between;
	margin: auto;
	max-width: 1104px;

	&__video {
		position: relative;
		flex: none;
		width: 736px;
		height: 414px;
		background: url("../images/index-about.jpg") center no-repeat;
		background-size: cover;

		&.futuro {
			background-image: url("../images/video-futuro.jpg");
		}

		&.avenue {
			background-image: url("../images/video-avenue.jpg");
		}

		&.fonte {
			background-image: url("../images/video-fonte.jpg");
		}
	}

	&__desc {
		flex: 1;
		padding-top: 80px;
		max-width: 300px;
	}
}

.about-phil {
	margin: auto;
	max-width: 1200px;

	.heading {
		margin-bottom: 60px;

		&__text {
			max-width: 573px;
		}

		&__desc {
			max-width: 520px;
		}
	}

	&__btn {
		margin-top: 115px;
		max-width: 368px;
	}
}

.quote {
	position: relative;
	z-index: 2;
	display: flex;
	margin: 60px auto 217px;
	max-width: 1104px;
	background-color: #f7f7f7;

	h2 {
		margin-bottom: 20px;
		max-width: 430px;
		font-size: 40px;
	}

	p {
		margin-top: 20px;
		color: rgba(#162b32, 0.8);
	}

	.slider-pagination {
		position: absolute;
		right: 72px;
		bottom: 72px;
		z-index: 4;
		transform: translate3d(0, 0, 0);
	}

	.image-slider {
		position: relative;
		flex: none;
		width: 368px;
		height: auto;
		min-height: 490px;
		// background: url("../images/quote-video.jpg") center no-repeat;

		.video-preview {
			position: absolute;
			width: 100%;
			height: 100%;

			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.video-container {
			width: 100%;
			height: 100%;

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__col {
		position: relative;
		padding: 96px 88px 72px 80px;

		&::before {
			content: "";
			position: absolute;
			right: 54px;
			top: 0;
			z-index: 1;
			display: block;
			width: 127px;
			height: 103px;
			background: url('../images/quotes.svg') center no-repeat;
			background-size: cover;
			pointer-events: none;
		}
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__author {
		margin-top: 50px;

		&__name {
			margin-bottom: 2px;
			font-family: $font-franklingothic;
			font-weight: 300;
			font-size: 26px;
			line-height: 1.15;
			color: #162b32;
		}

		&__title {
			font-size: 15px;
			line-height: 1.6;
			color: rgba(#162b32, 0.5);
		}
	}
}

.about-rewards {
	overflow: hidden;
	margin: 152px auto 156px;
	padding-bottom: 36px;

	&__inner {
		background-color: #0e2026;
		background-image: url('../images/award-leaf.svg');
		background-size: auto 91.2%;
		background-repeat: no-repeat;
		background-position: center bottom;
	}
}

.about-people {
	margin: 156px auto 120px;
	max-width: 1200px;

	.heading {
		&__text {
			max-width: 626px;
		}

		&__desc {
			max-width: 440px;
		}
	}

	&__image {
		position: relative;
		z-index: 2;
		margin: auto;
		height: 699px;
		max-width: 1104px;
		background: url("../images/team.jpg") center no-repeat;
		background-size: cover;
	}
}

.about-news {
	overflow: hidden;
	margin: 120px auto 130px;
	padding-top: 80px;

	.heading {
		margin-bottom: 187px;

		&__text {
			max-width: 483px;
		}
	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}

.about-photos {
	overflow: hidden;
	margin: 130px auto 120px;
	padding-top: 80px;

	.heading {

	}

	&__inner {
		margin: auto;
		max-width: 1200px;
	}
}
