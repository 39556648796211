// stylelint-disable at-rule-empty-line-before

@import "variables";
@import "functions";
@import "mixins";
@import "sprites";
@import "fonts";
@import "vendor";
@import "base";
@import "typography";

@import "partials/header";
@import "partials/footer";

@import "partials/hero";

@import "partials/modals";
@import "partials/headings";
@import "partials/stats";
@import "partials/forms";
@import "partials/buttons";
@import "partials/sliders";
@import "partials/tabs";

@import "pages/index-page";
@import "pages/about-page";
@import "pages/contacts-page";
@import "pages/park-page";
@import "pages/pages-options";

@import "partials/gift";

body {
	min-width: 1200px;
}
