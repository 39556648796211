// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS

$color-black: #000;
$color-white: #fff;

$color-accent-red: #aa132c;

$color-codgray: #1a1a1a;
$color-btn: #f5db87;
$color-futuro: #25b7cc;
$color-fonte: #1d9b85;
$color-avenue: #01a8a6;

// FONTS
$font-opensans: "Open Sans", sans-serif;
$font-franklingothic: "Franklin Gothic", sans-serif;
$font-bebasneue: "BebasNeue", sans-serif;
$font-myriadpro: "MyriadPro", sans-serif;

