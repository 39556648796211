.tabs {
	&__nav {
		display: flex;
		align-items: center;
		margin-bottom: 88px;
	}

	&__btn {
		display: inline-flex;
		flex: none;
		align-items: center;
		margin-right: 7px;
		border: none;
		border-radius: 2px;
		padding: 0 24px;
		width: auto;
		height: 72px;
		max-width: 257px;
		box-shadow: none;
		font-weight: 300;
		font-size: 15px;
		line-height: 1.2;
		text-align: left;
		color: rgba(#0e2026, 0.6);
		background-color: $color-white;
		transition: background-color 0.25s cubic-bezier(0.25, 0.1, 0.25, 1);

		.is-device-desktop &:hover {
			background-color: #eceded;
		}

		&.is-current {
			color: $color-white;
			background-color: $color-futuro;
			cursor: auto;
		}
	}

	&__tab {
		animation: opacShow 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
}

.selectblock {
	margin-top: -168px;

	&__nav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 88px;
	}

	&__container {
		position: relative;
		z-index: 1;
	}

	&__tab {
		animation: opacShow 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
}

.progress-select {
	position: relative;
	z-index: 2;
	display: flex;
	flex: none;
	align-items: center;
	margin-left: 8px;
	border-radius: 3px;
	font-family: $font-franklingothic;
	font-weight: 300;
	font-size: 22px;
	line-height: 1;
	text-align: center;
	color: #202224;
	cursor: pointer;

	&__box {
		position: relative;
		height: 72px;
		background-color: $color-white;
	}

	&__selected {
		display: flex;
		align-items: center;
		padding: 0 24px;
		height: 100%;
		transition: color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
	}

	&__dropdown {
		position: absolute;
		left: 0;
		top: 100%;
		display: none;
		border-top: 2px solid transparent;
		border-radius: 3px;
		width: calc(100% + 74px);
		text-align: left;

		.progress-select.is-opened & {
			display: block;
		}

		.progress-select--year & {
			height: 231px;
		}

		.progress-select--month & {
			height: 289px;
		}
	}

	&__item {
		padding: 19px 52px 14px 24px;
		background-color: $color-white;
		cursor: pointer;

		&:not(:last-child) {
			border-bottom: 1px solid #f7f7f7;
		}

		.is-device-desktop &:hover {
			background-color: #f7f7f7;
		}

		&.is-current {
			display: flex;
			align-items: center;
			justify-content: center;

			&::after {
				content: "";
				display: block;
				margin-left: auto;
				border-radius: 50%;
				width: 6px;
				height: 6px;
				background-color: #25b7cc;
			}
		}
	}

	&__btn {
		display: flex;
		flex: none;
		align-items: center;
		justify-content: center;
		margin-left: 2px;
		width: 72px;
		height: 72px;
		background-color: $color-white;

		svg {
			width: 24px;
			height: 24px;
			transform: rotate(90deg) translateZ(0);
			transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

			.progress-select.is-opened & {
				transform: rotate(270deg) translateZ(0);
			}
		}
	}

	.is-device-desktop &:hover {
		.progress-select__selected {
			color: rgba(#202224, 0.5);
		}

		.progress-select__btn {
			svg {
				opacity: 0.5;
			}
		}
	}
}

.ss-scroll {
	margin-left: -4px;
	border-radius: 2px;
	width: 4px;
	background: #d6dcde;
}

@keyframes opacShow {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
